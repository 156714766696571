import { getReactComponent } from "./get-react-component";
import { tryParseJson } from "@common/try-parse-json.js";
var init = function (module) {
    var elements = document.querySelectorAll("." + module);
    if ((elements === null || elements === void 0 ? void 0 : elements.length) < 1)
        return;
    elements.forEach(function (element) {
        var _a, _b;
        if (!element)
            return;
        var props = element.getAttribute("data-" + module);
        if (props)
            props = (_a = tryParseJson(props)) !== null && _a !== void 0 ? _a : props;
        (_b = getReactComponent(module, element, props !== null && props !== void 0 ? props : undefined)) === null || _b === void 0 ? void 0 : _b.catch(function (error) {
            return console.log("Something went wrong while rendering react module: " + module, error);
        });
    });
};
var reactBootstrapper = function (modules) {
    if (!modules)
        return;
    var moduleList = Object.values(modules);
    document.addEventListener("DOMContentLoaded", function () {
        return moduleList.forEach(function (module) { return init(module); });
    });
};
export { reactBootstrapper };
